<template>
  <!-- Contents -->
  <main id="content" class="news_detail">
    <section class="news_group">
        <div class="news_title">
            <div class="title_info">
                <span class="info category" v-text="contentsDetail.contentCategoryName"></span>
                <!-- <span class="info update" v-text="this.$moment(contentsDetail.insertDate).format('YYYY.MM.DD')"></span> -->
                <span class="info update" v-text="this.$moment(contentsDetail.insertDate.replace(/\./g,'-')).format('YYYY.MM.DD')"></span>
            </div>
            <strong class="title" v-text="contentsDetail.title"></strong>
        </div>
      <!-- <div class="news_article" ref="contentEditor1" v-html="this.contentsDetail.description"></div> -->
      <div class="news_article2">
        <TinyViewer ref="tinyViewer" :description="contentsDetail.description"></TinyViewer>
      </div>
        <div class="news_share">
          <button class="share_link" @click="kakaoLink(contentsDetail.title,img)">
            <div class="ico_kakao">카카오 공유</div></button>
          <button class="share_link" @click="facebookLink"><div class="ico_facebook">페이스북 공유</div></button>
          <button class="share_link" @click="copyLink"><div class="ico_link">링크 공유</div></button>
          <button class="share_link" @click="fileDown" v-show="fileBoolean"><div class="ico_disk">파일 다운</div></button>
        </div>

        <div class="btn_bottom_wrap">
          <router-link :to="{ name: 'ContentsList' }" class="btn-basic">
            <em class="txt" v-html="$t('content.news.detail.list_view')">
            </em>
          </router-link>
        </div>
    </section>

    <section class="more_news">
        <!-- <div class="recommend_tab" role="tablist">
            <button role="tab" class="tab" :aria-selected="!range" @click="tabContents('pipe')"
            v-html="$t('content.news.detail.contents.related')">
            </button>
            <button role="tab" class="tab" :aria-selected="range" @click="tabContents('popularity')"
            v-html="$t('content.news.detail.contents.popular')">
            </button>
        </div> -->
        <MobileBaseTab
            v-model="tabValue"
            v-bind:list="[
                $t('content.news.detail.contents.related'),
                $t('content.news.detail.contents.popular')
            ]"
            tabtype="none"
            @input="tabContents">
        </MobileBaseTab>
        <div class="news_swiper">
          <div class="news_list">
            <MobileBaseSlider
              ref="swiperSlider"
              :pagination="true"
              :list="contentslist"
              :autoplay="false"
              :loop="false"
              :navigation="false"
            >
            <template v-slot="{ item }">
              <div class="news_item">
                <button @click="reset(item.id)" class="news">
                  <div class="thumb">
                    <template v-if="item.youtubeUrl">
                      <img :src="`https://img.youtube.com/vi/${youtubeUrl(item.youtubeUrl)}/0.jpg`"
                        alt="" class="img_thumb" data-aos="fade-up">
                    </template>
                    <template v-else-if="item.thumbnailFileUrl">
                      <img
                          data-aos="fade-up"
                          :src="item.thumbnailFileUrl"
                          alt="" class="img_thumb">
                    </template>
                  </div>
                  <strong class="category" v-text="item.contentCategoryName"></strong>
                  <strong class="title" v-text="item.title"></strong>
                  <div class="update" v-text="item.insertDate"></div>
                </button>
              </div>
            </template>
            </MobileBaseSlider>
          </div>
        </div>
    </section>
  </main>

  <!-- // CONTENTS -->
</template>

<script>
import ContentsDetail from '@/views/contents/ContentsDetail.vue';
import MobileBaseSlider from '@/components/slider/MobileBaseSlider.vue';
import MobileBaseTab from '@/components/base/MobileBaseTab.vue';
import TinyViewer from '@/components/base/TinyViewer.vue'

export default {
  name: 'MobileContentsDetail',
  extends: ContentsDetail,
  components: {
    MobileBaseSlider,
    MobileBaseTab,
    TinyViewer
  },
  data() {
    return {
      tabValue: 0,
    };
  },
  methods: {
    tabContents(tab) {
      switch (tab) {
        case 0:
          this.range = false;
          this.$refs.swiperSlider.$el.swiper.slideTo(0);
          this.pipeList(this.ncNoData);
          break;
        case 1:
          this.range = true;
          this.$refs.swiperSlider.$el.swiper.slideTo(0);
          this.popularityList();
          break;
        default:
          break;
      }
    }
  },
};
</script>

<style>
.news_article2 img {max-width:950px;}
.news_article2 p {margin-top:13px;margin-bottom:13px;}
.news_article2 h1, h2, h3, h4, h5, h6 {margin-top:13px;margin-bottom:13px;}

.news_article2 {
  padding: 60px 0 80px;
  font-size: 16px;
  line-height: 1.3;
  color: #666;
  overflow: hidden;
}
.news_article2 { margin-top: 2.8rem; overflow: hidden; color: #666; font-size: 1.5rem; line-height: 1.7; }
.news_article2 img { max-width: 100% !important; height: auto !important; }
.news_article2 iframe { height: 300px; margin: 0 !important; min-height: 32rem; }
.news_article2 .ql-editor { padding-left: 2.4rem; padding-right: 2.4rem; }
.news_detail .news_article2 { padding-left: 2.4rem; padding-right: 2.4rem; }

</style>
